<template>
  <div class="about">
    <page-header :title="$t('client.edit')" :desc="model.name">
      <li class="breadcrumb-item"><router-link :to="{name:'clients.index'}">{{$tc('client.name', 2)}}</router-link></li>
      <li class="breadcrumb-item">{{model.name}}</li>
    </page-header>
    <page-content>
      <ntm-block v-if="environment !== 'production'">
        <button @click="setCurrentLocalhostTenant()" :class="{'btn-success': model.isCurrentLocalhostTenant, 'btn-info': !model.isCurrentLocalhostTenant}" class="btn mb-3">Set current localhost tenant</button>
      </ntm-block>

      <form @submit.prevent="onSubmit()">
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{$t('save')}}</button>
        </ntm-block>
      </form>

      <ntm-block title="Trenutno stanje">
        <div class="block block-rounded block-link-pop text-center" >
          <div class="block-content block-content-full ratio ratio-1x1">
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <div class="fs-2 fw-bold text-body-color-dark">{{ model.gamesCount }}</div>
                <div class="fs-sm fw-semibold mt-1 text-uppercase text-muted">{{ $tc('game.played', 2) }}</div>
              </div>
            </div>
          </div>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>

<script>
import fields from './formDefinition'
import ClientService from '../../services/client.service'
import router from '@/router'
import { TokenService } from '@/services/storage.service'
import store from '@/store'

export default {
  // required properties, field, form, model
  data () {
    return {
      form: {},
      model: {},
      fields: fields,
      uploading: false,
      transmit: {
        acceptedFileTypes: ['image/*'],
        clickable: true,
        adapterOptions: {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + 'api/v1/admin/media/upload',
          headers: {
            Authorization: `Bearer ${TokenService.getToken()}`
          },
          params: {
            type: 'logo',
            uuid: '',
            assetType: 'client'
          }
        }
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    ClientService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  beforeRouteUpdate (to, from, next) {
    ClientService.show(to.params.uuid).then((response) => {
      this.setData(response.data)
      store.commit('loaded')
    })
  },
  computed: {
    environment () {
      return process.env.VUE_APP_ENV
    }
  },
  methods: {
    setCurrentLocalhostTenant () {
      ClientService.setCurrentLocalhostTenant(this.model.uuid).then((response) => {
        store.commit('loaded')
      })
    },
    setData (data) {
      this.model = data
      this.transmit.adapterOptions.params.uuid = this.model.uuid
    },
    updateLogo () {
      ClientService.show(this.$route.params.uuid).then((response) => {
        this.uploading = false
        this.model.logo = response.data.logo
      })
    },
    onSubmit () {
      ClientService.update(this.model.uuid, this.model).then((response) => {
        router.push({ name: 'clients.index' })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>
