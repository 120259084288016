import ApiService from './api.service'

const ClientService = {
  index (search = '') {
    return ApiService.get('api/v2/superadmin/clients?search=' + search)
  },

  show (uuid) {
    return ApiService.get('api/v2/superadmin/clients/' + uuid)
  },

  update (uuid, data) {
    return ApiService.put('api/v2/superadmin/clients/' + uuid, data)
  },

  store (data) {
    return ApiService.post('api/v2/superadmin/clients', data)
  },

  setCurrentLocalhostTenant (uuid) {
    return ApiService.post('api/v2/superadmin/clients/' + uuid + '/set-current-localhost-tenant')
  }
}

export default ClientService
