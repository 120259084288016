<template>
   <div class="form-group" :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
      <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
      <date-picker v-model="model[field.key]" label="" :locale="$t('locale-datepicker')" :formatted="dateFormat" :output-format="options.outputFormat" :placeholder="to.placeholder" :only-time="to.onlyTime ? to.onlyTime : false" :only-date="to.onlyDate ? to.onlyDate : false" :minute-interval="5" :format="options.format"></date-picker>
      <span class="help-block form-text text-danger"
            v-if="form.$errors[field.key].length > 0"
            v-text="$t('validation.' + field.key + '.' + form.$errors[field.key][0])" />
      <!-- <error-display v-if="form.$errors[field.key].lenght > 0" :form="form" :field="field.key"></error-display> -->
   </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
export default {
  mixins: [baseField],
  data () {
    return {
      date: new Date(),
      options: {
        format: 'YYYY-MM-DD HH:mm',
        outputFormat: 'YYYY-MM-DD HH:mm:ss',
        useCurrent: true
      }
    }
  },
  computed: {
    dateFormat () {
      let format = 'YYYY-MM-DD HH:mm'
      if (this.to.onlyDate) {
        format = 'YYYY-MM-DD'
      }

      if (this.to.onlyTime) {
        format = 'HH:mm'
      }

      return format
    }
  }
  // components: {
  //   datepicker
  // }
}
</script>
